<template>
  <div class="forgotPass">
    <div class="main">
        <div class="forgotPass-box">
            <div v-if="!status">
                <el-form
                    ref="myForm"
                    label-position="right"
                    label-width="145px"
                    :model="form"
                    :rules="rules"
                    >
                    <el-form-item label="メールアドレス" prop="Email">
                        <el-input
                            style="width:300px"
                            placeholder="メールアドレスを入力して下さい"
                            v-model="form.Email"
                        ></el-input>
                        <span class="getCodeBtn" @click="_getCode">検証コード取得</span>
                    </el-form-item>
                    <el-form-item label="検証コード" prop="code">
                        <el-input
                            style="width:300px"
                            placeholder="5桁の検証コードを入力下さい"
                            v-model="form.code"
                        ></el-input>
                    </el-form-item>
                    <div class="btn-box">
                         <span class="getCodeBtn" @click="next">次へ</span>
                    </div>
                </el-form>
            </div>
            <div v-if="status">
                <el-form
                    ref="myForm"
                    label-position="right"
                    label-width="145px"
                    :model="passForm"
                    :rules="rules"
                    >
                    <el-form-item label="パスワード" prop="password">
                    <el-input
                        placeholder="パスワードを入力して下さい"
                        type="password"
                         style="width:300px"
                        v-model="passForm.password"
                    ></el-input>
                    </el-form-item>
                    <el-form-item label="パスワード確認" prop="checkPass">
                    <el-input
                        placeholder=" パスワードを再度入力して下さい"
                        type="password"
                         style="width:300px"
                        v-model="passForm.checkPass"
                    ></el-input>
                    </el-form-item>
                    <div class="btn-box">
                        <span class="getCodeBtn" @click="goback">前へ</span>
                         <span class="getCodeBtn" @click="submit">提出</span>
                    </div>
            </el-form>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import service from '@/service'
import utils from "@/assets/js/utils";
export default {
    name: 'forgotPass',
    
    data() {
    //確認用パスワード
    const checkPassWord = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("パスワードを入力して下さい"));
      }
      setTimeout(() => {
        if (value.length < 6) {
          return callback(new Error("パスワードは6位以上でお願いします"));
        } else {
          callback();
        }
      }, 500);
    };
    //確認用パスワード入力
    const checkPass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("パスワードを確認して下さい"));
      } else if (value !== this.passForm.password) {
        return callback(new Error("二回入力したパスワードは不一致しています"));
      } else {
        callback();
      }
    };
    const checkEmail = (rule,value,callback) => {
      if(value !== this.loginInfo.Email) {
        return callback(new Error("二回入力したパスワードは不一致しています"));
      }else{
        callback();
      }
    };
        return {
            status: false,
            form: {
                Email: '',
                code: ''
            },
            passForm: {
                password: '',
                checkPass: '',
            },
        rules: {
            Email: [
            { required: true, validator: utils.checkUserEmail, trigger: "blur" },
            ],
            password: [
            { required: true, validator: checkPassWord, trigger: "blur" },
            ],
            checkPass: [{ required: true, validator: checkPass, trigger: "blur" }],
            checkEmail: [{ required: true, validator: checkEmail, trigger: "blur" }]
        },
        }
    },
    methods: {
        _getCode() {
            if(!this.form.Email) {
                this.$message.error("メールアドレスを入力下さい");
                return
            }
            const params = {
                username: this.form.Email
            }
            return service.home.getCode(params).then(res => {
                if(res.status !== 200) {
                    this.$message.error(res.info);
                    return
                }
                this.$message.success(res.info);
            })
        },

        next() {
            if(!this.form.Email) {
                this.$message.error("メールアドレスを入力下さい");
                return
            }
            if(!this.form.code) {
                this.$message.error("5桁の検証コードを入力下さい")
                return
            }
            if(this.form.code.length !== 5) {
                this.$message.error("検証コードは５桁となります")
                return
            }
            this.status = true
        },
        goback() {
            this.status = false
        },
        submit() {
            if(this.passForm.password != this.passForm.checkPass) {
                
                this.$message.error('パスワードは一致していません')
                return
            }
            const params = {
                username: this.form.Email,
                newpwd: this.passForm.password,
                cfmpwd: this.passForm.checkPass,
                captcha: this.form.code
            }
            return service.home.resetPassWord(params).then(res => {
                if(res.status !== 200) {
                    this.$message.error(res.info)
                    return
                } else {
                    this.$message.success("パスワードをリセットしました")
                    this.$router.push('/login')
                }

                
            })
        }
    }
}

</script>
<style lang="scss" scoped>
    .main {
		height: 100vh;
		background: #999;
        .forgotPass-box {
            background-color: #fff;
			width: 600px;
			height: 400px;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			border-radius: 40px;
            padding: 30px;
            >div {
                margin-top: 100px;
                .getCodeBtn {
                    display: inline-block;
                    width: 100px;
                    height: 35px;
                    line-height: 35px;
                    background-color: #f03747;
                    text-align: center;
                    color: #fff;
                    margin-left: 20px;
                    border-radius: 10px;
                    cursor: pointer;
                }
                .btn-box {
                    text-align: center;
                    margin-top: 50px;
                }
            }
        }
    }
</style>